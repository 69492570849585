@import "../../variables.css";

.InitialsScreen {
  background-color: var(--coral);
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
}

.InitialsWrapper {
  transition: transform 30s linear;
  cursor: pointer;
  outline: none;
  background: none;
  border: none;
  transform: translateX(10vw);
  text-decoration: none;
}

.InitialsWrapper:hover {
  transform: scale(1.5) rotate(-5deg);
}

.Initials {
  transition: color 2s ease-out;
  font-family: 'Allerta', sans-serif;
  color: var(--purple-blue);
  font-size: 100vmin;
  letter-spacing: 0.11em;
  line-height: 1;
  margin-right: -0.11em;
}

.Initials:hover {
  color: var(--purple-blue-darker);
}
