@import "variables.css";

.Screen {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: relative;
  flex-shrink: 0;
}

.Heading1 {
  font-size: 9vmin;

  letter-spacing: 0.11em;
  text-transform: uppercase;

  color: var(--off-white);

  align-self: flex-start;
  margin-right: 8.88vmin;
}

.Heading2 {
  font-size: 4vmin;

  letter-spacing: 0.11em;
  text-transform: uppercase;

  color: var(--purple);

  align-self: flex-start;
}

.Text {
  font-size: 3vmin;
  color: var(--pink);
  letter-spacing: 0.11em;
}

.GraphicsBlock {
  width: 100vmin;
  height: 100vmin;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  flex-shrink: 0;
}

.ContentBlock {
  padding: 4vmin;
  background-color: var(--off-white);
  width: calc(100vmin - 8vmin);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

@media (orientation: landscape) and (max-aspect-ratio: 3/2) {
  .ContentBlock {
    position: absolute;
    right: 0;
    height: calc(100vh - 8vmin);
    width: 40vw;
  }
}

@media (orientation: portrait) and (min-aspect-ratio: 2/3) {
  .ContentBlock {
    position: absolute;
    bottom: 0;
    width: calc(100vw - 8vmin);
  }
}

.LinkList {
  font-size: 3vmin;
  text-transform: uppercase;
  letter-spacing: 0.11em;
  line-height: 1.3;
  list-style: none;
  padding: 0;
  margin: 0;
}

.Link {
  text-decoration: none;
  color: inherit;
}
