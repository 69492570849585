
.WaveScreen {
  background-color: var(--off-white);
  display: flex;
}

@media (orientation: portrait) {
  .WaveScreen {
    flex-direction: column;
    justify-content: space-between;
  }
}

@media (orientation: landscape) {
  .WaveScreen {
    flex-direction: row;
  }
}

.WaveGraphicsBlock {
  background-color: #D54E12;
  flex-direction: row;
}

.WaveGraphicsWrapper {
  width: 85vmin;
  height: 85vmin;
  position: relative;
  overflow: hidden;
  align-self: flex-end;
  justify-self: flex-end;
}

.WaveRectangleWrapper {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.WaveRectangleInner {
  transform: rotate(-45deg);
  position: relative;
  display: flex;
  flex-direction: row;
}

.WaveRectangle {
  outline: none;
  border: none;
  width: 142vmin;
  height: 142vmin;
}

.WaveEllipseWrapper {
  position: relative;
  margin-left: -17vmin;
}

.WaveEllipse {
  transition: border-color 2s ease-out;
  cursor: pointer;
  position: absolute;
  border-radius: 100%;
  outline: none;
  border-color: transparent;
  width: 34vmin;
  height: 34vmin;
  border-width: 6vmin;
  border-style: solid;
  box-sizing: border-box;
}

.WaveRectangle1, .WaveEllipse1, .WaveEllipse2, .WaveEllipse3 {
  background-color: #F8E500;
}

.WaveRectangle2, .WaveEllipse4, .WaveEllipse5 {
  background-color: #D54E12;
}

.WaveEllipse1:hover,
.WaveEllipse2:hover,
.WaveEllipse3:hover {
  border-color: #9e8b00;
}
.WaveEllipse4:hover,
.WaveEllipse5:hover {
  border-color: #7b0000;
}


.WaveEllipse2 {
  left: 34vmin;
  top: 34vmin;
}

.WaveEllipse3 {
  left: 68vmin;
  top: 68vmin;
}

.WaveEllipse4 {
  left: 34vmin;
}

.WaveEllipse5 {
  left: 68vmin;
  top: 34vmin;
}

.WaveLinkList {
  color: #D54E12;
}
