@import "../../variables.css";

.FlagScreen {
  background-color: var(--off-white);
  display: flex;
}

@media (orientation: portrait) {
  .FlagScreen {
    flex-direction: column;
    justify-content: space-between;
  }
}

@media (orientation: landscape) {
  .FlagScreen {
    flex-direction: row;
  }
}

.FlagGraphicsBlock {
  flex-direction: row;
}

.FlagGraphicsWrapper {
  width: 100vmin;
  height: 100vmin;
  position: relative;
  overflow: hidden;
}

.FlagRectangleWrapper {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.FlagRectangleInner {
  transform: rotate(45deg);
  position: relative;
  display: flex;
  flex-direction: row;
}

.FlagRectangle {
  transition: background-color 2s ease-out;
  cursor: pointer;
  outline: none;
  border: none;
  width: 142vmin;
  height: 142vmin;
}

.FlagRectangle:hover {
  background-color: var(--deep-pink);
}

.FlagRectangle1 {
  background-color: var(--flag-blue);
}

.FlagRectangle2 {
  background-color:  var(--deep-pink-lighter);
}

.FlagLinkList {
  color:  var(--flag-blue);
}
