
.Screen2 {
  background-color: var(--off-white);
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
}

@media (orientation: portrait) {
  .Screen2 {
    flex-direction: column;
  }
}

@media (orientation: landscape) {
  .Screen2 {
    flex-direction: row;
  }
}

.DotFieldGraphicsBlock {
  background-color: #E19997;
  flex-direction: row;
  align-items: flex-end;
}

.GraphicsWrapper {
  width: 78%;
  height: 78.5%;
  position: relative;
}

.Rectangle {
  cursor: pointer;
  transition: background-color 2s ease-out;
  background: var(--off-white);
  position: absolute;
  border-radius: 20px;
  outline: none;
  border: none;
  width: 11vmin;
  height: 1.7vmin;
}

.Ellipse {
  cursor: pointer;
  transition: background-color 2s ease-out;
  position: absolute;
  background: var(--off-white);
  border-radius: 100%;
  outline: none;
  border: none;
  width: 9.4vmin;
  height: 9.4vmin;
}

.Rectangle:hover,
.Ellipse:hover {
  background-color: #821150;
}

.Rectangle15 {
  left: 48.85%;
  right: 36.62%;
  top: 74.22%;
  bottom: 23.69%;
  transform: rotate(-29.65deg);
}

.Rectangle18 {
  left: 3.72%;
  right: 81.75%;
  top: 12.63%;
  bottom: 85.27%;
  transform: rotate(-29.65deg);
}

.Rectangle16 {
  left: 50.41%;
  right: 35.05%;
  top: 78.04%;
  bottom: 19.86%;
  transform: rotate(-25.49deg);
}

.Rectangle19 {
  left: 5.28%;
  right: 80.19%;
  top: 16.46%;
  bottom: 81.45%;
  transform: rotate(-25.49deg);
}

.Rectangle17 {
  left: 51.9%;
  right: 33.56%;
  top: 82.36%;
  bottom: 15.55%;
  transform: rotate(-25.49deg);
}

.Rectangle20 {
  left: 6.77%;
  right: 78.7%;
  top: 20.77%;
  bottom: 77.13%;
  transform: rotate(-25.49deg);
}

.Ellipse13 {
  left: 11.97%;
  right: 75.93%;
  top: 25.32%;
  bottom: 62.52%
}

.Ellipse18 {
  left: -1.99%;
  right: 89.89%;
  top: 24.05%;
  bottom: 63.93%
}

.Ellipse23 {
  left: 25.78%;
  right: 61.97%;
  top: 26.31%;
  bottom: 61.53%
}

.Ellipse31 {
  left: 39.74%;
  right: 48.15%;
  top: 27.3%;
  bottom: 60.54%
}

.Ellipse36 {
  left: 53.7%;
  right: 34.19%;
  top: 28.57%;
  bottom: 59.41%
}

.Ellipse41 {
  left: 67.52%;
  right: 20.23%;
  top: 28.57%;
  bottom: 59.41%
}

.Ellipse28 {
  left: 19.8%;
  right: 68.09%;
  top: 0%;
  bottom: 87.98%
}

.Ellipse32 {
  left: 33.62%;
  right: 54.13%;
  top: 0.99%;
  bottom: 86.99%
}

.Ellipse37 {
  left: 47.58%;
  right: 40.31%;
  top: 2.12%;
  bottom: 85.71%
}

.Ellipse42 {
  left: 61.4%;
  right: 26.35%;
  top: 2.12%;
  bottom: 85.71%
}

.Ellipse46 {
  left: 75.36%;
  right: 12.39%;
  top: 5.94%;
  bottom: 81.9%
}

.Ellipse47 {
  left: 85.75%;
  right: 1.99%;
  top: 15.42%;
  bottom: 72.56%
}

.Ellipse48 {
  left: 87.89%;
  right: 0%;
  top: 29.99%;
  bottom: 57.85%
}

.Ellipse49 {
  left: 85.75%;
  right: 1.99%;
  top: 45.69%;
  bottom: 42.29%
}

.Ellipse14 {
  left: 14.81%;
  right: 73.08%;
  top: 38.47%;
  bottom: 49.36%
}

.Ellipse19 {
  left: 0.85%;
  right: 87.04%;
  top: 37.2%;
  bottom: 50.78%
}

.Ellipse24 {
  left: 28.63%;
  right: 59.12%;
  top: 39.46%;
  bottom: 48.37%
}

.Ellipse33 {
  left: 42.59%;
  right: 45.3%;
  top: 40.45%;
  bottom: 47.38%
}

.Ellipse38 {
  left: 56.55%;
  right: 31.34%;
  top: 41.73%;
  bottom: 46.11%
}

.Ellipse43 {
  left: 70.37%;
  right: 17.38%;
  top: 41.73%;
  bottom: 46.11%
}

.Ellipse29 {
  left: 22.65%;
  right: 65.24%;
  top: 13.15%;
  bottom: 74.82%
}

.Ellipse34 {
  left: 36.47%;
  right: 51.28%;
  top: 14.14%;
  bottom: 73.83%
}

.Ellipse39 {
  left: 50.43%;
  right: 37.46%;
  top: 15.42%;
  bottom: 72.56%
}

.Ellipse44 {
  left: 64.39%;
  right: 23.5%;
  top: 15.42%;
  bottom: 72.56%
}

.Ellipse15 {
  left: 17.95%;
  right: 69.8%;
  top: 51.77%;
  bottom: 36.21%
}

.Ellipse20 {
  left: 4.13%;
  right: 83.76%;
  top: 50.35%;
  bottom: 37.48%
}

.Ellipse25 {
  left: 31.91%;
  right: 55.98%;
  top: 52.76%;
  bottom: 35.22%
}

.Ellipse35 {
  left: 45.87%;
  right: 42.02%;
  top: 53.75%;
  bottom: 34.23%
}

.Ellipse40 {
  left: 59.69%;
  right: 28.06%;
  top: 54.88%;
  bottom: 32.96%
}

.Ellipse45 {
  left: 73.65%;
  right: 14.25%;
  top: 54.88%;
  bottom: 32.96%
}

.Ellipse16 {
  left: 20.8%;
  right: 66.95%;
  top: 64.92%;
  bottom: 23.06%
}

.Ellipse21 {
  left: 6.98%;
  right: 80.91%;
  top: 63.51%;
  bottom: 24.33%
}

.Ellipse26 {
  left: 34.76%;
  right: 53.13%;
  top: 65.91%;
  bottom: 22.07%
}

.Ellipse17 {
  left: 24.07%;
  right: 63.68%;
  top: 78.08%;
  bottom: 9.76%
}

.Ellipse22 {
  left: 10.11%;
  right: 77.64%;
  top: 76.8%;
  bottom: 11.17%
}

.Ellipse27 {
  left: 38.03%;
  right: 49.86%;
  top: 79.07%;
  bottom: 8.77%
}

.Ellipse30 {
  left: 40.88%;
  right: 47.01%;
  top: 92.36%;
  bottom: -4.38%
}
