@import "../../variables.css";

.Burger {
  display: flex;
  flex-direction: column;
  background: transparent;
  border: 0;
  outline: none;
  padding: 0;
  margin: 2.55vmin 2.33vmin 0 0;
  position: absolute;
  top: 0;
  right: 0;
}

.BurgerPiece {
  width: 4.22vmin;
  height: .4vmin;
  margin: .4vmin 0;
}

.BurgerPiece.-default {
  background-color: var(--off-white);
}

.BurgerPiece.-pink {
  background-color: var(--pink);
}

.BurgerPiece.-blue {
  background-color: var(--flag-blue);
}

.BurgerPiece.-red {
  background-color: var(--red);
}

.BurgerPiece.-purple-blue {
  background-color: var(--purple-blue);
}
