@import url(https://fonts.googleapis.com/css?family=Allerta&display=swap);
body {
  margin: 0;
  font-family: 'Allerta', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  --off-white: #F7F0E5;
  --purple: #6054A0;
  --purple-blue: #514B9C;
  --purple-blue-darker: #38346D;
  --pink: #E19997;
  --red: #D54E12;
  --deep-pink: #821150;
  --flag-blue: #247D99;
  --deep-pink-lighter: #AE4386;
  --coral: #DA6250;
  --ocean-blue: #4594A6;
  --ocean-wave-blue: #0C78E0;
  --ocean-wave-blue-darker: #003ca4;
  --brown: #98836F;
  --sausage: #E3996E;
  --sausage-darker: #D76C2E;
  --green: #58984A;
  --green-darker: #20381B;
  --black: #101010 ;
}

.Screen {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: relative;
  flex-shrink: 0;
}

.Heading1 {
  font-size: 9vmin;

  letter-spacing: 0.11em;
  text-transform: uppercase;

  color: var(--off-white);

  align-self: flex-start;
  margin-right: 8.88vmin;
}

.Heading2 {
  font-size: 4vmin;

  letter-spacing: 0.11em;
  text-transform: uppercase;

  color: var(--purple);

  align-self: flex-start;
}

.Text {
  font-size: 3vmin;
  color: var(--pink);
  letter-spacing: 0.11em;
}

.GraphicsBlock {
  width: 100vmin;
  height: 100vmin;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  flex-shrink: 0;
}

.ContentBlock {
  padding: 4vmin;
  background-color: var(--off-white);
  width: calc(100vmin - 8vmin);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

@media (orientation: landscape) and (max-aspect-ratio: 3/2) {
  .ContentBlock {
    position: absolute;
    right: 0;
    height: calc(100vh - 8vmin);
    width: 40vw;
  }
}

@media (orientation: portrait) and (min-aspect-ratio: 2/3) {
  .ContentBlock {
    position: absolute;
    bottom: 0;
    width: calc(100vw - 8vmin);
  }
}

.LinkList {
  font-size: 3vmin;
  text-transform: uppercase;
  letter-spacing: 0.11em;
  line-height: 1.3;
  list-style: none;
  padding: 0;
  margin: 0;
}

.Link {
  text-decoration: none;
  color: inherit;
}

.ForestScreen {
  background-color: var(--purple);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.ForestScreenGraphicsWrapper {
  width: 33vmax;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin-bottom: -7vmax;
  justify-content: center;
}

.Tree {
  transition: background-color 2s ease-out;
  width: 10vmax;
  height: 10vmax;
  border-radius: 100%;
  background-color: #58984A;
  margin: .5vmax;
  outline: none;
  border: none;
  cursor: pointer;
}

.Tree:hover {
  background-color: #20381B;
}

.Page {
  display: flex;
  flex-direction: row;
  background-color: var(--black);
  height: 100vh;
  width: 100vw;
}

.ScreenWrapper {
  position: relative;
  transition: margin-left .3s ease-in-out;
}

.ScreenWrapperPushLeft {
  margin-left: -20vw;
}

.Navigation {
  display: flex;
  width: 20vw;
  height: 100vh;
}

.NavigationIsDefault {
}

.NavigationIsActive {
}

.NavigationList {
  align-self: center;
  margin: 2.8vmin 6vmin 2.8vmin 2.5vmin;
  padding: 0;
  list-style: none;
}

.NavigationItem {
  font-size: 3vmin;
  text-transform: uppercase;
  letter-spacing: 0.11em;
}

.NavigationLink {
  text-decoration: none;
}

.Burger {
  display: flex;
  flex-direction: column;
  background: transparent;
  border: 0;
  outline: none;
  padding: 0;
  margin: 2.55vmin 2.33vmin 0 0;
  position: absolute;
  top: 0;
  right: 0;
}

.BurgerPiece {
  width: 4.22vmin;
  height: .4vmin;
  margin: .4vmin 0;
}

.BurgerPiece.-default {
  background-color: var(--off-white);
}

.BurgerPiece.-pink {
  background-color: var(--pink);
}

.BurgerPiece.-blue {
  background-color: var(--flag-blue);
}

.BurgerPiece.-red {
  background-color: var(--red);
}

.BurgerPiece.-purple-blue {
  background-color: var(--purple-blue);
}


.Screen2 {
  background-color: var(--off-white);
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
}

@media (orientation: portrait) {
  .Screen2 {
    flex-direction: column;
  }
}

@media (orientation: landscape) {
  .Screen2 {
    flex-direction: row;
  }
}

.DotFieldGraphicsBlock {
  background-color: #E19997;
  flex-direction: row;
  align-items: flex-end;
}

.GraphicsWrapper {
  width: 78%;
  height: 78.5%;
  position: relative;
}

.Rectangle {
  cursor: pointer;
  transition: background-color 2s ease-out;
  background: var(--off-white);
  position: absolute;
  border-radius: 20px;
  outline: none;
  border: none;
  width: 11vmin;
  height: 1.7vmin;
}

.Ellipse {
  cursor: pointer;
  transition: background-color 2s ease-out;
  position: absolute;
  background: var(--off-white);
  border-radius: 100%;
  outline: none;
  border: none;
  width: 9.4vmin;
  height: 9.4vmin;
}

.Rectangle:hover,
.Ellipse:hover {
  background-color: #821150;
}

.Rectangle15 {
  left: 48.85%;
  right: 36.62%;
  top: 74.22%;
  bottom: 23.69%;
  transform: rotate(-29.65deg);
}

.Rectangle18 {
  left: 3.72%;
  right: 81.75%;
  top: 12.63%;
  bottom: 85.27%;
  transform: rotate(-29.65deg);
}

.Rectangle16 {
  left: 50.41%;
  right: 35.05%;
  top: 78.04%;
  bottom: 19.86%;
  transform: rotate(-25.49deg);
}

.Rectangle19 {
  left: 5.28%;
  right: 80.19%;
  top: 16.46%;
  bottom: 81.45%;
  transform: rotate(-25.49deg);
}

.Rectangle17 {
  left: 51.9%;
  right: 33.56%;
  top: 82.36%;
  bottom: 15.55%;
  transform: rotate(-25.49deg);
}

.Rectangle20 {
  left: 6.77%;
  right: 78.7%;
  top: 20.77%;
  bottom: 77.13%;
  transform: rotate(-25.49deg);
}

.Ellipse13 {
  left: 11.97%;
  right: 75.93%;
  top: 25.32%;
  bottom: 62.52%
}

.Ellipse18 {
  left: -1.99%;
  right: 89.89%;
  top: 24.05%;
  bottom: 63.93%
}

.Ellipse23 {
  left: 25.78%;
  right: 61.97%;
  top: 26.31%;
  bottom: 61.53%
}

.Ellipse31 {
  left: 39.74%;
  right: 48.15%;
  top: 27.3%;
  bottom: 60.54%
}

.Ellipse36 {
  left: 53.7%;
  right: 34.19%;
  top: 28.57%;
  bottom: 59.41%
}

.Ellipse41 {
  left: 67.52%;
  right: 20.23%;
  top: 28.57%;
  bottom: 59.41%
}

.Ellipse28 {
  left: 19.8%;
  right: 68.09%;
  top: 0%;
  bottom: 87.98%
}

.Ellipse32 {
  left: 33.62%;
  right: 54.13%;
  top: 0.99%;
  bottom: 86.99%
}

.Ellipse37 {
  left: 47.58%;
  right: 40.31%;
  top: 2.12%;
  bottom: 85.71%
}

.Ellipse42 {
  left: 61.4%;
  right: 26.35%;
  top: 2.12%;
  bottom: 85.71%
}

.Ellipse46 {
  left: 75.36%;
  right: 12.39%;
  top: 5.94%;
  bottom: 81.9%
}

.Ellipse47 {
  left: 85.75%;
  right: 1.99%;
  top: 15.42%;
  bottom: 72.56%
}

.Ellipse48 {
  left: 87.89%;
  right: 0%;
  top: 29.99%;
  bottom: 57.85%
}

.Ellipse49 {
  left: 85.75%;
  right: 1.99%;
  top: 45.69%;
  bottom: 42.29%
}

.Ellipse14 {
  left: 14.81%;
  right: 73.08%;
  top: 38.47%;
  bottom: 49.36%
}

.Ellipse19 {
  left: 0.85%;
  right: 87.04%;
  top: 37.2%;
  bottom: 50.78%
}

.Ellipse24 {
  left: 28.63%;
  right: 59.12%;
  top: 39.46%;
  bottom: 48.37%
}

.Ellipse33 {
  left: 42.59%;
  right: 45.3%;
  top: 40.45%;
  bottom: 47.38%
}

.Ellipse38 {
  left: 56.55%;
  right: 31.34%;
  top: 41.73%;
  bottom: 46.11%
}

.Ellipse43 {
  left: 70.37%;
  right: 17.38%;
  top: 41.73%;
  bottom: 46.11%
}

.Ellipse29 {
  left: 22.65%;
  right: 65.24%;
  top: 13.15%;
  bottom: 74.82%
}

.Ellipse34 {
  left: 36.47%;
  right: 51.28%;
  top: 14.14%;
  bottom: 73.83%
}

.Ellipse39 {
  left: 50.43%;
  right: 37.46%;
  top: 15.42%;
  bottom: 72.56%
}

.Ellipse44 {
  left: 64.39%;
  right: 23.5%;
  top: 15.42%;
  bottom: 72.56%
}

.Ellipse15 {
  left: 17.95%;
  right: 69.8%;
  top: 51.77%;
  bottom: 36.21%
}

.Ellipse20 {
  left: 4.13%;
  right: 83.76%;
  top: 50.35%;
  bottom: 37.48%
}

.Ellipse25 {
  left: 31.91%;
  right: 55.98%;
  top: 52.76%;
  bottom: 35.22%
}

.Ellipse35 {
  left: 45.87%;
  right: 42.02%;
  top: 53.75%;
  bottom: 34.23%
}

.Ellipse40 {
  left: 59.69%;
  right: 28.06%;
  top: 54.88%;
  bottom: 32.96%
}

.Ellipse45 {
  left: 73.65%;
  right: 14.25%;
  top: 54.88%;
  bottom: 32.96%
}

.Ellipse16 {
  left: 20.8%;
  right: 66.95%;
  top: 64.92%;
  bottom: 23.06%
}

.Ellipse21 {
  left: 6.98%;
  right: 80.91%;
  top: 63.51%;
  bottom: 24.33%
}

.Ellipse26 {
  left: 34.76%;
  right: 53.13%;
  top: 65.91%;
  bottom: 22.07%
}

.Ellipse17 {
  left: 24.07%;
  right: 63.68%;
  top: 78.08%;
  bottom: 9.76%
}

.Ellipse22 {
  left: 10.11%;
  right: 77.64%;
  top: 76.8%;
  bottom: 11.17%
}

.Ellipse27 {
  left: 38.03%;
  right: 49.86%;
  top: 79.07%;
  bottom: 8.77%
}

.Ellipse30 {
  left: 40.88%;
  right: 47.01%;
  top: 92.36%;
  bottom: -4.38%
}

.SausageScreen {
  background-color: var(--brown);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.SausageGraphicsWrapper {
  position: relative;
  width: 116vw;
  display: flex;
  flex-direction: row;
  margin-left: -5vmin;
  height: 85.5vmin;
}

.SausageRectangle {
  transition: background-color 2s ease-out;
  cursor: pointer;
  height: 34.5vmin;
  width: 8.1vmin;
  background-color: var(--sausage);
  border-radius: 7.7vmin;
  margin-right: .35vmin;
  margin-left: .35vmin;
  flex-shrink: 0;
  border: 0;
  outline: 0;
}

.SausageRectangle:hover {
  background-color: var(--sausage-darker);
}


.WaveScreen {
  background-color: var(--off-white);
  display: flex;
}

@media (orientation: portrait) {
  .WaveScreen {
    flex-direction: column;
    justify-content: space-between;
  }
}

@media (orientation: landscape) {
  .WaveScreen {
    flex-direction: row;
  }
}

.WaveGraphicsBlock {
  background-color: #D54E12;
  flex-direction: row;
}

.WaveGraphicsWrapper {
  width: 85vmin;
  height: 85vmin;
  position: relative;
  overflow: hidden;
  align-self: flex-end;
  justify-self: flex-end;
}

.WaveRectangleWrapper {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.WaveRectangleInner {
  transform: rotate(-45deg);
  position: relative;
  display: flex;
  flex-direction: row;
}

.WaveRectangle {
  outline: none;
  border: none;
  width: 142vmin;
  height: 142vmin;
}

.WaveEllipseWrapper {
  position: relative;
  margin-left: -17vmin;
}

.WaveEllipse {
  transition: border-color 2s ease-out;
  cursor: pointer;
  position: absolute;
  border-radius: 100%;
  outline: none;
  border-color: transparent;
  width: 34vmin;
  height: 34vmin;
  border-width: 6vmin;
  border-style: solid;
  box-sizing: border-box;
}

.WaveRectangle1, .WaveEllipse1, .WaveEllipse2, .WaveEllipse3 {
  background-color: #F8E500;
}

.WaveRectangle2, .WaveEllipse4, .WaveEllipse5 {
  background-color: #D54E12;
}

.WaveEllipse1:hover,
.WaveEllipse2:hover,
.WaveEllipse3:hover {
  border-color: #9e8b00;
}
.WaveEllipse4:hover,
.WaveEllipse5:hover {
  border-color: #7b0000;
}


.WaveEllipse2 {
  left: 34vmin;
  top: 34vmin;
}

.WaveEllipse3 {
  left: 68vmin;
  top: 68vmin;
}

.WaveEllipse4 {
  left: 34vmin;
}

.WaveEllipse5 {
  left: 68vmin;
  top: 34vmin;
}

.WaveLinkList {
  color: #D54E12;
}

.OceanScreen {
  background-color: var(--ocean-blue);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.OceanGraphicsWrapper {
  position: relative;
  width: 100vw;
  height: 60vmin;
  display: flex;
  flex-direction: row;
  overflow: hidden;
}

.OceanGraphicsInner {
  transform: rotate(-37.22deg);
  display: flex;
  flex-direction: row;
  margin-left: -34.2vmin;
  transform-origin: left;
}

.OceanRectangle {
  transition: background-color 2s ease-out;
  cursor: pointer;
  height: 300vmin;
  width: 8.8vmin;
  background-color: var(--ocean-wave-blue);
  margin-right: 3.9vmin;
  margin-left: 3.2vmin;
  flex-shrink: 0;
  border: 0;
  outline: none;
}

.OceanRectangle:hover {
  background-color: var(--ocean-wave-blue-darker);
}

.FlagScreen {
  background-color: var(--off-white);
  display: flex;
}

@media (orientation: portrait) {
  .FlagScreen {
    flex-direction: column;
    justify-content: space-between;
  }
}

@media (orientation: landscape) {
  .FlagScreen {
    flex-direction: row;
  }
}

.FlagGraphicsBlock {
  flex-direction: row;
}

.FlagGraphicsWrapper {
  width: 100vmin;
  height: 100vmin;
  position: relative;
  overflow: hidden;
}

.FlagRectangleWrapper {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.FlagRectangleInner {
  transform: rotate(45deg);
  position: relative;
  display: flex;
  flex-direction: row;
}

.FlagRectangle {
  transition: background-color 2s ease-out;
  cursor: pointer;
  outline: none;
  border: none;
  width: 142vmin;
  height: 142vmin;
}

.FlagRectangle:hover {
  background-color: var(--deep-pink);
}

.FlagRectangle1 {
  background-color: var(--flag-blue);
}

.FlagRectangle2 {
  background-color:  var(--deep-pink-lighter);
}

.FlagLinkList {
  color:  var(--flag-blue);
}

.InitialsScreen {
  background-color: var(--coral);
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
}

.InitialsWrapper {
  transition: transform 30s linear;
  cursor: pointer;
  outline: none;
  background: none;
  border: none;
  transform: translateX(10vw);
  text-decoration: none;
}

.InitialsWrapper:hover {
  transform: scale(1.5) rotate(-5deg);
}

.Initials {
  transition: color 2s ease-out;
  font-family: 'Allerta', sans-serif;
  color: var(--purple-blue);
  font-size: 100vmin;
  letter-spacing: 0.11em;
  line-height: 1;
  margin-right: -0.11em;
}

.Initials:hover {
  color: var(--purple-blue-darker);
}

