:root {
  --off-white: #F7F0E5;
  --purple: #6054A0;
  --purple-blue: #514B9C;
  --purple-blue-darker: #38346D;
  --pink: #E19997;
  --red: #D54E12;
  --deep-pink: #821150;
  --flag-blue: #247D99;
  --deep-pink-lighter: #AE4386;
  --coral: #DA6250;
  --ocean-blue: #4594A6;
  --ocean-wave-blue: #0C78E0;
  --ocean-wave-blue-darker: #003ca4;
  --brown: #98836F;
  --sausage: #E3996E;
  --sausage-darker: #D76C2E;
  --green: #58984A;
  --green-darker: #20381B;
  --black: #101010 ;
}
