@import "../../variables.css";

.SausageScreen {
  background-color: var(--brown);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.SausageGraphicsWrapper {
  position: relative;
  width: 116vw;
  display: flex;
  flex-direction: row;
  margin-left: -5vmin;
  height: 85.5vmin;
}

.SausageRectangle {
  transition: background-color 2s ease-out;
  cursor: pointer;
  height: 34.5vmin;
  width: 8.1vmin;
  background-color: var(--sausage);
  border-radius: 7.7vmin;
  margin-right: .35vmin;
  margin-left: .35vmin;
  flex-shrink: 0;
  border: 0;
  outline: 0;
}

.SausageRectangle:hover {
  background-color: var(--sausage-darker);
}
