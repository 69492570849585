@import "../../variables.css";

.Page {
  display: flex;
  flex-direction: row;
  background-color: var(--black);
  height: 100vh;
  width: 100vw;
}

.ScreenWrapper {
  position: relative;
  transition: margin-left .3s ease-in-out;
}

.ScreenWrapperPushLeft {
  margin-left: -20vw;
}
