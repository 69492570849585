@import "../../variables.css";

.ForestScreen {
  background-color: var(--purple);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.ForestScreenGraphicsWrapper {
  width: 33vmax;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin-bottom: -7vmax;
  justify-content: center;
}

.Tree {
  transition: background-color 2s ease-out;
  width: 10vmax;
  height: 10vmax;
  border-radius: 100%;
  background-color: #58984A;
  margin: .5vmax;
  outline: none;
  border: none;
  cursor: pointer;
}

.Tree:hover {
  background-color: #20381B;
}
