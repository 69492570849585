@import "../../variables.css";

.OceanScreen {
  background-color: var(--ocean-blue);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.OceanGraphicsWrapper {
  position: relative;
  width: 100vw;
  height: 60vmin;
  display: flex;
  flex-direction: row;
  overflow: hidden;
}

.OceanGraphicsInner {
  transform: rotate(-37.22deg);
  display: flex;
  flex-direction: row;
  margin-left: -34.2vmin;
  transform-origin: left;
}

.OceanRectangle {
  transition: background-color 2s ease-out;
  cursor: pointer;
  height: 300vmin;
  width: 8.8vmin;
  background-color: var(--ocean-wave-blue);
  margin-right: 3.9vmin;
  margin-left: 3.2vmin;
  flex-shrink: 0;
  border: 0;
  outline: none;
}

.OceanRectangle:hover {
  background-color: var(--ocean-wave-blue-darker);
}
