@import "../../variables.css";

.Navigation {
  display: flex;
  width: 20vw;
  height: 100vh;
}

.NavigationIsDefault {
}

.NavigationIsActive {
}

.NavigationList {
  align-self: center;
  margin: 2.8vmin 6vmin 2.8vmin 2.5vmin;
  padding: 0;
  list-style: none;
}

.NavigationItem {
  font-size: 3vmin;
  text-transform: uppercase;
  letter-spacing: 0.11em;
}

.NavigationLink {
  text-decoration: none;
}
